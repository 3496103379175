import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import moment from 'moment';

import Layout from '../../components/layout';
import SEO from '../../components/seo/seo';
import './interview.scss';
import Share from '../../components/share/share';
import Gradient from '../../utils/gradient';
import HeroGradient from '../../components/hero/hero-gradient';

export const query = graphql`
  query ($slug: String!) {
    interviewsJson(slug: { eq: $slug }) {
      slug
      name
      title
      timestamp
      person
      avatarClass
      iframe
      youtube
      og {
        publicURL
      }
      avatar {
        publicURL
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      answers {
        questionId
        answer
      }
      links {
        url
        linkType
      }
    }
    allQuestionsJson {
      nodes {
        questionId
        question
        slug
      }
    }
  }
`;

const ICON_MAP = {
  twitter: {
    icon: 'fab fa-twitter',
    helpText: 'Twitter',
  },
  facebook: {
    icon: 'fab fa-facebook-f',
    helpText: 'Facebook',
  },
  instagram: {
    icon: 'fab fa-instagram',
    helpText: 'Instagram',
  },
  linkedin: {
    icon: 'fab fa-linkedin-in',
    helpText: 'LinkedIn',
  },
  telegram: {
    icon: 'fab fa-telegram',
    helpText: 'Telegram',
  },
  discord: {
    icon: 'fab fa-discord',
    helpText: 'Discord',
  },
  reddit: {
    icon: 'fab fa-reddit',
    helpText: 'Reddit',
  },
  team: {
    icon: 'fas fa-user-friends',
    helpText: 'Team',
  },
  website: {
    icon: 'fas fa-link',
    helpText: 'Website',
  },
  token: {
    icon: 'fas fa-coins',
    helpText: 'Tokenomics',
  },
  whitePaper: {
    icon: 'fas fa-paperclip',
    helpText: 'White Paper',
  },
  medium: {
    icon: 'fab fa-medium',
    helpText: 'Medium',
  },
  youtube: {
    icon: 'fab fa-youtube',
    helpText: 'YouTube',
  },
};

const Interview = ({ data, location }) => {
  const interview = data.interviewsJson;
  const questions = data.allQuestionsJson.nodes;

  useEffect(() => {
    const gradient = new Gradient();
    gradient.initGradient('#canvas-gradient1');
  }, []);
  console.log(interview.og);
  return (
    <Layout>
      <HeroGradient id="canvas-gradient1" />
      <SEO
        title={`${interview.name} | Interviews by Airlyft`}
        keywords={[
          `interview`,
          interview.name,
          `startups`,
          `marketing`,
          'blockchain',
          'crypto',
          'airlyft',
          'kyte',
          `social media expert`,
          `facebook expert`,
          `twitter expert`,
        ]}
        description={interview.title}
        imageURL={interview.og.publicURL}
      />
      <section className="interview-page">
        <div className="container is-max-widescreen p-4">
          <div className="interview-page__item-block interview-page__user-intro pt-5">
            <Image
              className={`mt-4 ${interview.avatarClass}`}
              fluid={interview.avatar.childImageSharp.fluid}
              alt={interview.title}
              style={{ width: 120, height: 120, borderRadius: '50%' }}
            />

            <p className="title is-size-4 mt-4 mb-1">{interview.name}</p>

            <p
              style={{
                fontSize: 18,
                maxWidth: 480,
                margin: '0 auto',
                marginBottom: '20px',
              }}
            >
              {interview.title}
            </p>
            <div className="buttons are-small">
              {interview.links
                .filter((item) => !!ICON_MAP[item.linkType])
                .map((item, i) => (
                  <a
                    href={item.url}
                    key={i}
                    rel="noreferrer nofollow noopener"
                    className="button is-primary is-outlined styled-button"
                    target="_blank"
                    style={{ borderRadius: '50%', height: 32, width: 32 }}
                  >
                    <i
                      style={{ fontSize: 16 }}
                      className={ICON_MAP[item.linkType].icon}
                    ></i>
                  </a>
                ))}
            </div>
          </div>
        </div>
      </section>

      <div className="container is-max-widescreen p-4 mt-4">
        <div className="box p-0 interview-page__item-block">
          <div
            className="pl-6 pr-6 interview-page__item-block__header"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingTop: 32,
              paddingBottom: 32,
              borderBottom: '1px solid #ebebeb',
              background: '#f8f8f8',
              alignItems: 'center',
            }}
          >
            <p style={{ margin: 0 }}>
              <i> Published on {moment(interview.timestamp).format('LL')} </i>
            </p>
            <Share
              title={`Sneak peak into ${interview.name} at Interviews by AirLyft`}
              pageURL={`/interview/${interview.slug}`}
            />
          </div>
          <div className="pl-6 pr-6 interview-page__item-block__content">
          {interview.youtube && <div className='video-container' dangerouslySetInnerHTML={{ __html: interview.youtube }} />}
          {interview.iframe && <div style={{ 'marginTop': '32px' }} dangerouslySetInnerHTML={{ __html: interview.iframe }} />}
            {interview.answers.map((item) => {
              const questionObject =
                (questions || []).find(
                  (q) => q.questionId === item.questionId
                ) || {};

              return (
                <div
                  className=""
                  style={{
                    borderBottom: '1px solid #ebebeb',
                    paddingTop: 32,
                    paddingBottom: 32,
                  }}
                  key={item.questionId}
                >
                  <p
                    className="has-text-weight-semibold interview-page__question"
                    style={{ fontSize: 18 }}
                  >
                    {questionObject.question}
                  </p>

                  <div
                    className="interview-page__answer"
                    style={{ fontSize: 18 }}
                  >
                    <i>{interview.person}</i> -{' '}
                    <div
                      style={{ display: "contents" }}
                      dangerouslySetInnerHTML={{ __html: item.answer }}
                    ></div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Interview;
